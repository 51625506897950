@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family:
    sans-serif,
    --aple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans", "Liberation Sans",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    @apply bg-darkgray text-white;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1 {
  font-size: 7vh;
  font-weight: bold;
}

h2 {
  font-size: 5vh;
  font-weight: bold;
}

h3 {
  font-size: 3vh;
  font-weight: bold;
}


.gitbutton {
  @apply bg-pink-600 px-12 py-3 text-sm shadow-sm font-medium tracking-wider text-pink-100 rounded-full hover:shadow-2xl hover:bg-pink-700 cursor-pointer text-center font-bold;
}

.appStoreButton {
  @apply bg-gray-600 px-12 py-3 text-sm shadow-sm font-medium tracking-wider text-pink-100 rounded-full hover:shadow-2xl hover:bg-gray-700 cursor-pointer text-center font-bold;
}


/* Fading */

.fade-wrapper {
  transition:opacity ease-in-out;
}
.fade-out {
  opacity:0;
}
.fade-in {
  opacity:1;
}